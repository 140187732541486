.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.register1 {
  color: white;
  position: relative;
  width: 70%;
  height: 40px;
  overflow: hidden;
}
.register1 input {
  color: white;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  border: none;
  outline: none;
}
.register1 label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid;
  pointer-events: none;
}
.register1 label::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid orchid;
  transform: translateX(-150%);
  transition: transform 0.4s ease-in;
}
.content-name {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.4s ease-in;
}
.register1 input:focus + .label-name .content-name,
.register1 input:valid + .label-name .content-name {
  transform: translateY(-20px);
}
.register1 input:focus + .label-name::before,
.register1 input:valid + .label-name::before {
  transform: translateX(0);
}
.form button {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
