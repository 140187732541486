.login1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form button {
  color: white;
  border-radius: 10px;
  width: 20%;
  height: 30px;
  font-weight: 600;
}
.login2 {
  background-color: red;
}

.login2 {
  position: relative;
  width: 70%;
  height: 50px;
  overflow: hidden;
}
.login2 input {
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  border: none;
  outline: none;
}

.login2 label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid;
  pointer-events: none;
}
.login2 label::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0px;
  border-bottom: 3px solid greenyellow;
  transform: translateX(-150%);
  transition: transform 0.4s ease;
}
.content-name {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: all 0.5s ease;
}
.login2 input:focus + .label-name .content-name,
.login2 input:valid + .label-name .content-name {
  transform: translateY(-20px);
}
.login2 input:focus + .label-name::before,
.login2 input:valid + .label-name::before {
  transform: translateX(0);
}
