.abt1 {
  display: flex;
  position: relative;
  align-items: center;
}
.abt1 .dns {
  margin-top: 100px;
}
.abt1 .dns1 {
  width: 750px;
  margin: auto;
  text-align: justify;
}

.abt1 .dns2 {
  width: 750px;
  margin: auto;
  text-align: justify;
}
.abt1 .dns3 {
  width: 750px;
  margin: auto;
  text-align: justify;
}
.abt1 .dns4 {
  width: 750px;
  margin: auto;
  text-align: justify;
}
.abt1 .dns5 {
  width: 750px;
  margin: auto;
  text-align: justify;
}
.abt1 .dns6 {
  width: 750px;
  margin: auto;
  text-align: justify;
}
.abt1 .card {
  height: 400px;
  background: transparent;
  align-items: center;
}
.svg {
  width: 1500px;
}
.card img {
  margin-bottom: 20px;
}
.abt2 {
  background-color: #2b3856;
  margin-top: 200px;
}
.card p {
  font-size: 16px;
}
.wdd {
  text-align: justify;
}
.dnsi {
  align-items: center;
  margin: auto;
}
.dip2 {
  margin: auto;
  justify-content: center;
}
.listing {
  color: white;
  position: relative;
  text-align: justify;
}
