.answer {
  background-color: #2b3856;
  color: white;
}
.answer1 {
  background-color: #2b3856;
  color: white;
}
.answer2 {
  background-color: #2b3856;
  color: white;
}
.answer3 {
  background-color: #2b3856;
  color: white;
}
.qans {
  margin-top: 100px;
}
