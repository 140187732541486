@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

.pnf {
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: blue;
  border: 1px solid blue;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}

.pnf-btn:hover {
  background-color: black;
  color: white;
}
