Carousel.Item {
  object-fit: contain;
}
.pd12 {
  height: 50vh;
}
.pd12 img {
  width: 100%;
  margin-left: 300px;
  height: 85vh;
}

@media screen and (max-width: 700px) {
  .pd12 .cwr {
    display: none;
  }

  .pd12 img {
    width: 100vw !important;
    height: 80vh !important;
    margin-left: 0px !important;
  }
}
.carousel-indicators [data-bs-target] {
  color: blue;
  border-radius: 100%;
  width: 55px;
  height: 15px;
}
.pd12 h3 {
  color: white;
}
.pd12 .cwr {
  background-color: rgb(5, 19, 119);
  top: 0px;
  left: 0px;
  height: 90vh;
  width: 500px;
}
.cwr {
  border-radius: 0px 0px 10px 0;
}

.right.carousel-control,
.left.carousel-control {
  display: none;
}
.sl {
  color: aliceblue;
  font-weight: 700;
  width: 400px;
  margin-left: 50px;
  text-align: left;
}
.cwr .car1 {
  margin-top: 50px;
  font-size: 50px;
  margin-left: 50px;
  width: 400px;
  text-align: left;
}
