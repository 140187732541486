@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.navbar {
  background-color: #2b3856;
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;

  border-bottom: solid gray !important;
  position: relative;
  display: flex;
}
@media screen and (max-width: 1000px) {
  button {
    color: white !important;
    background-color: white !important;
  }
}
.navbar-collapse {
  display: flex;
  flex-direction: row;
}

.donsp {
  font-size: 30px;
}
.donspsub {
  font-size: 20px;
  margin-left: 10px;
}
.active {
  border-bottom: 2px solid yellow;
}

.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
  margin: 0px;
}

.navbar-collapse .logo1 {
  font-size: 40px;
  padding: 7px;
}
.navbar-collapse .logo2 {
  font-size: 40px;
}
.navbar-collapse .logo3 {
  font-size: 40px;
}
.navbar-collapse .logo4 {
  font-size: 40px;
}
.navbar-collapse .logo5 {
  font-size: 40px;
}
.nav-link:hover {
  background-color: dodgerblue;
}
.nav-item .nav-link {
  font-weight: 600;
}
