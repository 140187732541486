.cnt {
  letter-spacing: 8px;
  font-size: 35px;
  font-weight: 500;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.dip3 {
  font-family: myriad-font-ariel;
  padding-left: 90px;
}
